"use client";

import { ImpactOccurredFunction, NotificationOccurredFunction, SelectionChangedFunction } from '@/hooks/use-haptic-feedback';
import { logError } from '@/lib/actions';
import { Locale } from '@/types/enums';
import { IWebApp } from '@/types/telegram';
import { createContext, useContext } from 'react';

interface TelegramContextType {
    webApp: IWebApp;
    hapticFeedback: readonly [
        ImpactOccurredFunction,
        NotificationOccurredFunction,
        SelectionChangedFunction,
    ],
    cloudStorage: {
        getItem: (key: string) => Promise<string | null>;
        setItem: (key: string, value: string) => Promise<void>;
        getItems: (keys: string[]) => Promise<Record<string, string | null>>;
        removeItem: (key: string) => Promise<void>;
        removeItems: (keys: string[]) => Promise<void>;
        getKeys: () => Promise<string[]>;
    };
    initialLocale: Locale;
    isExpanded: boolean;
};

export const TelegramContext = createContext<TelegramContextType | undefined>(undefined);

export const useTelegram = () => {
    const context = useContext(TelegramContext);
    if (!context) {
        logError('useTelegram must be used within a TelegramProvider');
        throw new Error('useTelegram must be used within a TelegramProvider');
    }
    return context;
};
