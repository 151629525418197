'use client';

import { IWebApp } from "@/types/telegram";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { TelegramContext } from "@/contexts/telegram-context";
import useHapticFeedback from "@/hooks/use-haptic-feedback";
import { Locale } from "@/types/enums";
import { I18nextProvider, useTranslation } from "react-i18next";
import { isLocaleSupported } from "@/lib/utils";
import i18n from "@/lib/i18n";
import useCloudStorage from "@/hooks/use-cloud-storage";

export const TelegramProvider = ({ children, webApp }: { children: ReactNode; webApp: IWebApp }) => {
    const { ready } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isExpanded, setIsExpanded] = useState<boolean>(webApp.isExpanded);
    const hapticFeedback = useHapticFeedback(webApp.HapticFeedback);
    const cloudStorage = useCloudStorage(webApp.CloudStorage);
    const [initialLocale, setInitialLocale] = useState<Locale>(Locale.En);

    const handleViewPortExpanded = useCallback((event: { isStateStable: boolean; viewportHeight: number; }) => {
        if (event.isStateStable) {
            webApp.ready();
            webApp.expand();
            webApp.ready();
            webApp.disableVerticalSwipes();
        }
    }, [webApp]);

    useEffect(() => {
        if (!ready) return;

        if (webApp.initDataUnsafe.user?.language_code && isLocaleSupported(webApp.initDataUnsafe.user.language_code)) {
            setInitialLocale(webApp.initDataUnsafe.user.language_code as Locale);
        }
    }, [ready, webApp]);

    const handleLanguageLoaded = useCallback(() => {
        console.log('handle language loaded.');
    }, []);

    const handleLanguageLoadingFailed = useCallback((lng: string) => {
        if (lng !== Locale.En) i18n.changeLanguage(Locale.En);
    }, []);

    const handleLanguageChanged = useCallback((lng: string) => {
        console.log('Handle language changed!');
    }, []);

    useEffect(() => {
        webApp.onEvent('viewportChanged', handleViewPortExpanded);
        i18n.on('loaded', handleLanguageLoaded);
        i18n.on('languageChanged', handleLanguageChanged);
        i18n.on('failedLoading', handleLanguageLoadingFailed);

        return () => {
            webApp.offEvent('viewportChanged', handleViewPortExpanded);
            i18n.off("loaded", handleLanguageLoaded);
            i18n.off("languageChanged", handleLanguageChanged);
            i18n.off("failedLoading", handleLanguageLoadingFailed);
        };
    }, [webApp, handleViewPortExpanded, handleLanguageLoaded, handleLanguageChanged, handleLanguageLoadingFailed]);

    return (
        <TelegramContext.Provider value={{
            webApp,
            hapticFeedback,
            cloudStorage,
            initialLocale,
            isExpanded,
        }}>
            <I18nextProvider i18n={i18n}>
                {children}
            </I18nextProvider>
        </TelegramContext.Provider>
    )
};
