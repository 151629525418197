'use client';

import { useTelegram } from "@/contexts/telegram-context";
import { Button } from "./ui/button";
import { DialogClose, DialogDescription, DialogContent, DialogTitle, DialogHeader, DialogFooter } from "./ui/dialog";
import Link from "next/link";

const SettingsMenu: React.FC = () => {
    const { webApp } = useTelegram();
    
    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Settings</DialogTitle>
                <DialogDescription></DialogDescription>
                <div>
                    <Button asChild>
                        <Link href={`/${webApp.initDataUnsafe.user?.id}/referral`}>
                            Referral
                        </Link>
                    </Button>
                </div>
                <DialogFooter>
                    <DialogClose>
                        <Button>Close</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogHeader>
        </DialogContent>
    )
};

export default SettingsMenu;
