'use client';

import "./fonts/icomoon/style.css";
import './globals.css';

import localFont from "next/font/local";
import Script from "next/script";
import { useCallback, useState } from "react";
import { Provider } from "react-redux";
import store from '@/store/store';
import { logError } from "@/lib/actions";
import { IWebApp } from "@/types/telegram";
import { AppProvider } from "@/providers/app-provider";
import { TelegramProvider } from "@/providers/telegram-provider";
import { UiProvider } from "@/providers/ui-provider";
import { Nunito_Sans } from 'next/font/google';
import { ThemeProvider } from 'styled-components';
import Loader from '@/components/Loader';
import { StylesProvider } from "@/providers/style-provider";

const icoMoon = localFont({
    src: "./fonts/icomoon/icomoon.woff",
    weight: "100 900",
});

const MetaTags = () => (
    <>
        <meta name="robots" content="noindex, nofollow" />
        <meta http-equiv="Cache-Control" content="no-store" />
        <meta http-equiv="Pragma" content="no-cache" />
        <meta http-equiv="Expires" content="0" />
    </>
);

const nunito = Nunito_Sans({
    subsets: ['latin', 'cyrillic', 'latin-ext', 'cyrillic-ext'],
    weight: ['400', '500', '600', '700', '800'],
    display: 'swap',
    variable: "--font-sans",
});

const geistMono = localFont({
    src: "./fonts/GeistMonoVF.woff",
    variable: "--font-geist-mono",
    weight: "100 900",
});

export default function RootLayout({ children, }: Readonly<{ children: React.ReactNode; }>) {
    const [webApp, initWebApp] = useState<IWebApp | undefined>(undefined);

    const initialize = useCallback(() => {
        if (typeof window !== 'undefined') {
            try {
                const webApp = (window as any).Telegram?.WebApp;
                if (webApp) {
                    initWebApp(webApp as IWebApp);
                    webApp.disableVerticalSwipes();
                } else {
                    throw new Error('TelegramProvider: WebApp cannot be initialized. Data not found.');
                }
            } catch (error: any) {
                logError('TelegramProvider: failed on initializing WebApp.', { ...error });
                if (webApp) webApp.close();
            }
        }
    }, [webApp]);

    return (
        <html lang="en">
            <body className={`${nunito.className} ${geistMono.variable} antialiased`}>
                <Script
                    src="https://telegram.org/js/telegram-web-app.js"
                    strategy="lazyOnload"
                    onLoad={initialize}
                    onError={(error: unknown) =>
                        console.error(`TelegramProvider: failed to load Telegram WebApp script.`, error)
                    }
                />
                {webApp ? (
                    <TelegramProvider webApp={webApp}>
                        <Provider store={store}>
                            <ThemeProvider theme={{ theme: webApp.colorScheme }}>
                                <StylesProvider>
                                    <UiProvider>
                                        <AppProvider>
                                            <main className="app">
                                                {children}
                                            </main>
                                        </AppProvider>
                                    </UiProvider>
                                </StylesProvider>
                            </ThemeProvider>
                        </Provider>
                    </TelegramProvider>
                ) : (
                    <Loader visible={true} />
                )}
            </body>
        </html >
    );
}
