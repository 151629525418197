'use client';

import { IScanQrPopupCallback, IScanQrPopupParams, IWebApp } from '@/types/telegram';
import { useCallback } from 'react';

export type ShowScanQrPopupFunction = (params: IScanQrPopupParams, callback?: IScanQrPopupCallback) => void;
export type CloseScanQrPopupFunction = () => void;

const useScanQrPopup = (webApp: IWebApp): readonly [ShowScanQrPopupFunction, CloseScanQrPopupFunction] => {
    const showScanQrPopup: ShowScanQrPopupFunction = useCallback(
        (params, callback) => {
            const adaptedCallback = callback
                ? (text: string) => {
                    const result = callback(text);
                    return result === true;
                }
                : undefined;

            webApp.showScanQrPopup?.(params, adaptedCallback);
        },
    [webApp]);

    const closeScanQrPopup: CloseScanQrPopupFunction = useCallback(
        () => webApp.closeScanQrPopup?.(),
    [webApp]);

    return [showScanQrPopup, closeScanQrPopup] as const;
};

export default useScanQrPopup;