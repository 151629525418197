const CURRENCIES = [
    { value: 'usd', label: 'USD', icon: '/assets/icons.dollar.svg' },
    { value: 'eur', label: 'EUR', icon: '/assets/icons.euro.svg' },
    { value: 'gbp', label: 'GBP', icon: '/assets/icons.pound.svg' },
    { value: 'aud', label: 'AUD', icon: '/assets/icons.dollar.svg' },
    { value: 'cad', label: 'CAD', icon: '/assets/icons.dollar.svg' },
]

const CRYPTO_CURRENCIES = [
    {
        value: 'btc',
        label: 'BTC',
        name: 'Bitcoin',
        icon: '/assets/crypto/btc.svg',
        usd: 21182.50,
        exchange: {
            usd: 21182.50,
            eur: 19399.27,
            gbp: 16980.59,
            aud: 30374.620,
            cad: 28304.48
        },
        change: 1.25,
        isFavorite: true,
        isTrending: false
    },
    {
        value: 'busd',
        label: 'BUSD',
        name: 'Binance',
        icon: '/assets/crypto/busd.svg',
        usd: 1.00,
        exchange: {
            usd: 1.00,
        },
        change: 0.18,
        isFavorite: false,
        isTrending: true
    },
    {
        value: 'eth',
        label: 'ETH',
        name: 'Ethereum',
        icon: '/assets/crypto/eth.svg',
        usd: 1565.71,
        exchange: {
            usd: 1565.71,
        },
        change: -4.03,
        isFavorite: false,
        isTrending: false
    },
    {
        value: 'xrp',
        label: 'XRP',
        name: 'Ripple',
        icon: '/assets/crypto/xrp.svg',
        usd: 0.39,
        exchange: {
            usd: 0.39,
        },
        change: 0.05,
        isFavorite: true,
        isTrending: false
    },
    {
        value: 'sol',
        label: 'SOL',
        name: 'Solana',
        icon: '/assets/crypto/sol.svg',
        usd: 23.17,
        exchange: {
            usd: 23.17,
        },
        change: -7.14,
        isFavorite: true,
        isTrending: true
    },
    {
        value: 'usdt',
        label: 'USDT',
        name: 'Tether',
        icon: '/assets/crypto/usdt.svg',
        usd: 1.00,
        exchange: {
            usd: 1.00,
        },
        change: 5.09,
        isFavorite: false,
        isTrending: true
    },
    {
        value: 'ada',
        label: 'ADA',
        name: 'Cardano',
        icon: '/assets/crypto/ada.svg',
        usd: 0.35,
        exchange: {
            usd: 0.35,
        },
        change: 1.17,
        isFavorite: true,
        isTrending: true
    },
    {
        value: 'xph',
        label: 'XPH',
        name: 'Fantom',
        icon: '/assets/crypto/xph.svg',
        usd: 0.020018,
        exchange: {
            usd: 0.020018,
        },
        change: -0.58,
        isFavorite: false,
        isTrending: false
    },
    {
        value: 'matic',
        label: 'MATIC',
        name: 'Polygon',
        icon: '/assets/crypto/matic.svg',
        usd: 1.0482,
        exchange: {
            usd: 1.0482,
        },
        change: 3.92,
        isFavorite: true,
        isTrending: true
    },
    {
        value: 'link',
        label: 'LINK',
        name: '1Chainlink',
        icon: '/assets/crypto/link.svg',
        usd: 6.88,
        exchange: {
            usd: 6.88,
        },
        change: -6.11,
        isFavorite: true,
        isTrending: true
    },
    {
        value: 'doge',
        label: 'DOGE',
        name: 'Dogecoin',
        icon: '/assets/crypto/xrp.svg',
        usd: 0.20,
        exchange: {
            usd: 0.20,
        },
        change: -2.04,
        isFavorite: false,
        isTrending: false
    },
    {
        value: 'tezos',
        label: 'XTZ',
        name: 'Tezos',
        icon: '/assets/crypto/xtz.svg',
        usd: 1.99,
        exchange: {
            usd: 1.99,
        },
        change: 5.19,
        isFavorite: false,
        isTrending: true
    },
    {
        value: 'okb',
        label: 'OKB',
        name: 'OKB',
        icon: '/assets/crypto/okb.svg',
        usd: 3.99,
        exchange: {
            usd: 3.99,
        },
        change: 0.25,
        isFavorite: true,
        isTrending: true
    },
    {
        value: 'kadena',
        label: 'KDA',
        name: 'Kadena',
        icon: '/assets/crypto/kda.svg',
        usd: 0.99,
        exchange: {
            usd: 0.99,
        },
        change: -0.13,
        isFavorite: false,
        isTrending: false
    },
    {
        value: 'orchid',
        label: 'OXT',
        name: 'Orchid',
        icon: '/assets/crypto/orchid.svg',
        usd: 1.99,
        exchange: {
            usd: 1.99,
        },
        change: 7.08,
        isFavorite: true,
        isTrending: true
    }
]

const MERCHANTS = [
    { value: 'visa', label: 'Visa USD' },
    { value: 'mastercard', label: 'Mastercard USD' },
    { value: 'paypal', label: 'Paypal' },
    { value: 'apple', label: 'Apple Pay' },
    { value: 'google', label: 'Google Pay' },
]

export { CURRENCIES, CRYPTO_CURRENCIES, MERCHANTS };
