/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import { ReactNode, useCallback, useEffect, useState } from "react";
import { useTelegram } from "@/contexts/telegram-context";
import { toast, Toaster, ToastT } from 'sonner';
import { UiContext, useUi } from "@/contexts/ui-context";
// import { AlertDialog } from "@/components/ui/alert-dialog";
// import { Sheet } from "@/components/ui/sheet";
// import { Drawer } from "@/components/ui/drawer";
// import { Dialog } from "@/components/ui/dialog";
// import MainMenu from '@/components/custom/modals/MainMenu';
// import SettingsMenu from '@/components/custom/modals/SettingsMenu';
// import SetupMenu from '@/components/custom/modals/SetupMenu';
import { IToastAction } from '@/types/api';
import { IBottomButtonParams, IPopupParams, IScanQrPopupParams, IThemeParams } from '@/types/telegram';
import { ColorScheme } from '@/types/enums';
import useScanQrPopup from '@/hooks/use-scan-qr-popup';
import { useRouter } from 'next/navigation';
import { ThemeProvider } from "next-themes";
import { colors } from "@mui/material";
import MainMenu from "@/components/MainMenu";
import { Drawer } from "@/components/ui/drawer";
import { Dialog } from "@/components/ui/dialog";
import { Sheet } from "@/components/ui/sheet";
import { AlertDialog } from "@/components/ui/alert-dialog";
import SetupMenu from "@/components/SetupMenu";
import { getWidgetMenuIcon } from "@/lib/iconHelper";
import { useStyle } from "@/contexts/style-context";
import SettingsMenu from "@/components/SettingsMenu";
import i18n from "@/lib/i18n";

export const UiProvider = ({ children }: { children: ReactNode }) => {
    const { webApp } = useTelegram();
    const width = 768;
    const scanQrPopup = useScanQrPopup(webApp);
    const router = useRouter();
    const { toastThemes } = useStyle();
    const { mainButtonThemes } = useStyle();
    const [colorScheme, setColorScheme] = useState<ColorScheme>(webApp.colorScheme);
    const [themeParams, setThemeParams] = useState<Partial<IThemeParams>>(webApp.themeParams);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [drawerContent, setDrawerContent] = useState<ReactNode | null>(null);
    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);
    const [sheetContent, setSheetContent] = useState<ReactNode | null>(null);
    const [isMainMenuOpen, setIsMainMenuOpen] = useState<boolean>(false);
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState<boolean>(false);
    const [mainMenu, setMainMenuContent] = useState<ReactNode | null>(null);
    const [settingsMenu, setSettingsMenuContent] = useState<ReactNode | null>(null);
    // useSmoothButtonsTransition({
    //     show: MainButton?.show,
    //     hide: MainButton?.hide,
    //     currentShowedIdRef: system.MainButton,
    //     id: buttonId,
    // });

    const showModal = useCallback((content: ReactNode) => {
        setModalContent(content);
        setIsModalOpen(true);
    }, []);

    const hideModal = useCallback(() => {
        setIsModalOpen(false);
        setModalContent(null);
    }, []);

    const showDialog = useCallback((content: ReactNode) => {
        setDialogContent(content);
        setIsDialogOpen(true);
    }, []);

    const hideDialog = useCallback(() => {
        setIsDialogOpen(false);
        setDialogContent(null);
    }, []);

    const showDrawer = useCallback((content: ReactNode) => {
        setDrawerContent(content);
        setIsDrawerOpen(true);
    }, []);

    const hideDrawer = useCallback(() => {
        setIsDrawerOpen(false);
        setDrawerContent(null);
    }, []);

    const showSheet = useCallback((content: ReactNode) => {
        setSheetContent(content);
        setIsSheetOpen(true);
    }, []);

    const hideSheet = useCallback(() => {
        setIsSheetOpen(false);
        setSheetContent(null);
    }, []);

    const showToast = useCallback((toastData: IToastAction) => {
        switch (toastData.type) {
            case 'error':
                toast.info(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            case 'warning':
                toast.warning(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            case 'info':
                toast.info(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            case 'success':
                toast.success(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
            default:
                toast.message(toastData.title, {
                    id: toastData.toastId,
                    description: toastData.description,
                    duration: toastData.duration,
                    closeButton: toastData.closeButton,
                    dismissible: toastData.dismissible,
                });
                break;
        }
    }, []);

    const hideToast = useCallback((toastId?: string) => {
        toast.dismiss(toastId);
    }, []);

    const showLoadingToast = (toastId: string) => {
        toast.loading(toastThemes.loading.title, {
            id: toastId,
            description: toastThemes.loading.description,
        });
    };

    const showPopup = useCallback((params: IPopupParams) => {
        return new Promise<string>((resolve, reject) => {
            try {
                webApp.showPopup(params, (buttonId: string) => {
                    resolve(buttonId);
                });
            } catch (e) {
                reject(e);
            }
        });
    }, [webApp]);

    const showScanQrPopup = useCallback((params: IScanQrPopupParams) => {
        return new Promise<string>((resolve, reject) => {
            try {
                webApp.showScanQrPopup(params, (text: string) => {
                    resolve(text);
                    return true;
                });
            } catch (e) {
                reject(e);
                return false;
            }
        });
    }, [webApp]);

    const closeMainMenu = useCallback((): void => {
        setIsMainMenuOpen(false);
    }, []);

    const openMainMenu = useCallback((): void => {
        setIsMainMenuOpen(true);
        setMainMenuContent(
            <MainMenu
                onSelect={(menu: string) => {
                    router.push(`/${webApp.initDataUnsafe.user?.id}/${menu}/`);
                    closeMainMenu();
                }}
            />
        );
    }, [router, webApp.initDataUnsafe.user?.id, closeMainMenu]);

    const openSettingsMenu = useCallback((): void => {
        setIsSettingsMenuOpen(true);
        setSettingsMenuContent(<SettingsMenu />)
        // showDialog(<SettingsMenu onSelect={() => router.push('/')} />);
    }, []);

    const closeSettingsMenu = useCallback((): void => {
        setIsSettingsMenuOpen(false);
    }, []);

    const showSetupMenu = useCallback((): void => {
     showDialog(<SetupMenu userId={webApp.initDataUnsafe.user?.id as number} />)
    }, [showDialog, webApp.initDataUnsafe.user]);

    const hideSetupMenu = useCallback((): void => {
        hideDialog();
    }, [hideDialog]);

    const showMainButton = useCallback(() => {
        if (!webApp.MainButton.isVisible) webApp.MainButton.show();
    }, [webApp.MainButton]);

    const hideMainButton = useCallback(() => {
        if (webApp.MainButton.isVisible) webApp.MainButton.hide();
    }, [webApp.MainButton]);

    const setMainButtonParams = useCallback((params: IBottomButtonParams) => {
        webApp.MainButton.setParams(params);
    }, [webApp.MainButton]);

    const disableMainButton = useCallback(() => {
        if (webApp.MainButton.isActive) webApp.MainButton.disable();
    }, [webApp.MainButton]);

    const showSecondaryButton = useCallback(() => {
        if (!webApp.SecondaryButton.isVisible) webApp.SecondaryButton.show();
    }, [webApp.SecondaryButton]);

    const hideSecondaryButton = useCallback(() => {
        if (webApp.SecondaryButton.isVisible) webApp.SecondaryButton.hide();
    }, [webApp.SecondaryButton]);

    const showBackButton = useCallback(() => {
        if (!webApp.BackButton.isVisible) webApp.BackButton.show();
    }, [webApp.BackButton]);

    const hideBackButton = useCallback(() => {
        if (webApp.BackButton.isVisible) webApp.BackButton.hide();
    }, [webApp.BackButton]);

    const showSettingsButton = useCallback(() => {
        if (!webApp.SettingsButton.isVisible) webApp.SettingsButton.show();
    }, [webApp.SettingsButton]);

    const hideSettingsButton = useCallback(() => {
        if (webApp.SettingsButton.isVisible) webApp.SettingsButton.hide();
    }, [webApp.SettingsButton]);

    const getWidgetIcon = useCallback((menu: 'wallet-overview' | 'referral' | 'top-up' | 'swap' | 'withdraw' | 'market' | 'token-staking') => {
        return getWidgetMenuIcon(colorScheme, menu);
    }, [colorScheme]);

    useEffect(() => {
        showSettingsButton();

        return () => hideSettingsButton();
    }, [showSettingsButton, hideSettingsButton]);

    const handleLanguageChanged = useCallback(() => {
        webApp.MainButton.setParams(mainButtonThemes.menu);
    }, [webApp.MainButton, mainButtonThemes.menu]);

    useEffect(() => {
        i18n.on("languageChanged", handleLanguageChanged);

        return () => {
            i18n.off("languageChanged", handleLanguageChanged);
        }
    }, [handleLanguageChanged]);

    return (
        <UiContext.Provider value={{
            width,
            colorScheme,
            setColorScheme,
            setThemeParams,
            showModal,
            hideModal,
            showDialog,
            hideDialog,
            showDrawer,
            hideDrawer,
            showSheet,
            hideSheet,
            showToast,
            hideToast,
            showLoadingToast,
            showMainButton,
            hideMainButton,
            showSecondaryButton,
            hideSecondaryButton,
            showBackButton,
            hideBackButton,
            showSettingsButton,
            hideSettingsButton,
            showPopup,
            setMainButtonParams,
            disableMainButton,
            scanQrPopup,
            openMainMenu,
            closeMainMenu,
            openSettingsMenu,
            closeSettingsMenu,
            showSetupMenu,
            hideSetupMenu,
            getWidgetIcon,
            isMainMenuOpen,
            isSettingsMenuOpen,
        }}>
            <ThemeProvider
                attribute="class"
                defaultTheme={colorScheme}
                enableSystem
                disableTransitionOnChange
            >
                <Toaster
                    richColors={true}
                    theme={colorScheme}
                    position="top-right"
                    visibleToasts={2}
                />
                {settingsMenu && (
                    <Dialog open={isSettingsMenuOpen} onOpenChange={closeSettingsMenu}>
                        {settingsMenu}
                    </Dialog>
                )}
                {children}
                {mainMenu && (
                    <Drawer open={isMainMenuOpen} onOpenChange={closeMainMenu} onDrag={closeMainMenu}>
                        {mainMenu}
                    </Drawer>
                )}
                {drawerContent && (
                    <Drawer open={isDrawerOpen} onOpenChange={hideDrawer} onDrag={hideDrawer}>
                        {drawerContent}
                    </Drawer>
                )}
                {dialogContent && (
                    <Dialog open={isDialogOpen} onOpenChange={hideDialog}>
                        {dialogContent}
                    </Dialog>
                )}
                {sheetContent && (
                    <Sheet open={isSheetOpen} onOpenChange={hideSheet}>
                        {sheetContent}
                    </Sheet>
                )}
                {modalContent && (
                    <AlertDialog open={isModalOpen} onOpenChange={hideModal}>
                        {modalContent}
                    </AlertDialog>
                )}
            </ThemeProvider>
        </UiContext.Provider>
    )
};
