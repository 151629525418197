/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import * as React from "react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { useTelegram } from "../contexts/telegram-context";
import { useMemo, useState } from "react";
import { percentageToFloat } from "../lib/utils";
import { IThemeParams } from "../types/telegram";
import { ColorScheme, Level } from "../types/enums";
import { t } from "i18next";
import { StyleContext } from "@/contexts/style-context";
import { string } from "zod";

export enum Color {
    Success = '#10b981',
    Danger = '#ef4343',
    Info = '#2570a9',
    Warning = '#D65513',
    Light = '#fafafa',
    Dark = '#18181b',
    Black = '#00000',
    White = '#fffff',
    Purple = "#9d00ff",
    Gold = "#ffe08b",
};

export function StylesProvider({ children, ...props }: React.ComponentProps<typeof NextThemesProvider>) {
    const { webApp } = useTelegram();
    const [themeParams, setThemeParams] = useState<Partial<IThemeParams>>(webApp.themeParams);
    const [colorScheme, setColorScheme] = useState<ColorScheme>(webApp.colorScheme);

    const colors = {
        danger: "#ef4343",
        warning: "#D65513",
        info: "#2570a9",
        success: "#10b981",
        light: "#fafafa",
        dark: "#18181b",
        sectionBg: {
            light: "#e4e4e7",
            dark: "#3f3f46",
        },
        sectionSeparator: {
            light: "#d4d4d8",
            dark: "#52525b",
        },
        secondaryBgColor: {
            light: "#f1f1f1",
            dark: "#fffff",
        }
    };

    const styles = useMemo(() => ({
        bgColor: themeParams.bg_color ?? (colorScheme === 'light' ? colors.light : colors.dark),
        buttonColor: themeParams.button_color ?? colors.info,
        textColor: themeParams.text_color ?? (colorScheme === 'light' ? colors.dark : colors.light),
        buttonTextColor: themeParams.button_text_color ?? themeParams.text_color ?? (colorScheme === 'light' ? colors.dark : colors.light),
        destructiveTextColor: themeParams.destructive_text_color ?? colors.danger,
        sectionBgColor: themeParams.section_bg_color ?? (colorScheme === 'light' ? colors.sectionBg.light : colors.sectionBg.dark),
        sectionSeparatorBgColor: themeParams.section_separator_color ?? (colorScheme === 'light' ? colors.sectionSeparator.light : colors.sectionSeparator.dark),
        secondaryBgColor: themeParams.secondary_bg_color ?? (colorScheme === 'light' ? colors.secondaryBgColor.light : colors.secondaryBgColor.dark),
        headerBgColor: themeParams.header_bg_color ?? themeParams.bg_color ?? (colorScheme === 'light' ? colors.dark : colors.light),
        linkColor: themeParams.link_color ?? themeParams.section_header_text_color ?? themeParams.accent_text_color ?? colors.info,
        accentTextColor: themeParams.accent_text_color ?? themeParams.link_color ?? themeParams.section_header_text_color ?? colors.info,
        bottomBarBgColor: themeParams.bottom_bar_bg_color ?? themeParams.bg_color ?? (colorScheme === 'light' ? colors.dark : colors.light),
        hintColor: themeParams.hint_color ?? colors.info,
        successColor: Color.Success,
        dangerColor: Color.Danger,
        warningColor: Color.Warning,
        infoColor: Color.Info,
        blackColor: Color.Black,
        whiteColor: Color.White,
        goldColor: Color.Gold,
    }), [colorScheme, colors.danger, colors.dark, colors.info, colors.light, colors.secondaryBgColor.dark, colors.secondaryBgColor.light, colors.sectionBg.dark, colors.sectionBg.light, colors.sectionSeparator.dark, colors.sectionSeparator.light, themeParams.accent_text_color, themeParams.bg_color, themeParams.bottom_bar_bg_color, themeParams.button_color, themeParams.button_text_color, themeParams.destructive_text_color, themeParams.header_bg_color, themeParams.hint_color, themeParams.link_color, themeParams.secondary_bg_color, themeParams.section_bg_color, themeParams.section_header_text_color, themeParams.section_separator_color, themeParams.text_color]);

    const getTrendColor = (trendFactor: number | string): string => {
        const trend = percentageToFloat(trendFactor);

        if (trend > 0) {
            return Color.Success;
        } else if (trend === 0) {
            return Color.Info;
        } else {
            return Color.Danger;
        }
    };

    function addColorTransparency(color: string, opacity: number): string {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    function getBalanceColor(balance: number): string {
        const pozitiveBalanceColor = styles.accentTextColor ?? styles.linkColor ?? colors.info;

        if (balance > 0) {
            return styles.textColor;
        } else {
            return addColorTransparency(styles.textColor, 0.5);
        }
    };

    const mainButtonThemes = useMemo(() => ({
        close: {
            text: t('close'),
            text_color: styles.buttonTextColor,
            color: styles.buttonColor,
            is_active: true,
            is_visible: true,
            has_shine_effect: false,
        },
        confirm: {
            text: t('confirm'),
            text_color: styles.buttonTextColor,
            color: styles.buttonColor,
            is_active: true,
            is_visible: true,
            has_shine_effect: false,
        },
        continue: {
            text: t('continue'),
            text_color: styles.buttonTextColor,
            color: styles.buttonColor,
            is_active: true,
            is_visible: true,
            has_shine_effect: true,
        },
        menu: {
            text: t('menu'),
            text_color: styles.buttonTextColor,
            color: styles.buttonColor,
            is_active: true,
            is_visible: true,
            has_shine_effect: false,
        },
    }), [styles]);

    const toastThemes = useMemo(() => ({
        loading: {
            title: t('loading'),
            description: t('please_wait'),
        },
    }), []);

    function getErrorToast(toastId: string) {
        return {
            toastId,
            title: t('error.title'),
            description: t('error.desc'),
            type: Level.Error,
            duration: 2400,
            dismissible: true,
            closeButton: false,
        };
    }

    function getLoadingToast(toastId: string) {
        return {
            toastId,
            title: t('loading'),
            description: t('please_wait'),
            type: Level.Info,
        }
    }

    return (
        <StyleContext.Provider value={{ colors, styles, toastThemes, mainButtonThemes, getTrendColor, getBalanceColor, getErrorToast, getLoadingToast }}>
            <NextThemesProvider {...props}>
                {children}
            </NextThemesProvider>
        </StyleContext.Provider>
    );
};