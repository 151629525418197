import { IApiAction } from "@/types/api";

class AccessDeniedException extends Error {
    apiAction: IApiAction;
    userId: number;

    constructor(apiAction: IApiAction, userId: number) {
        super(`Access denied for the user #${userId}.`);
        this.apiAction = apiAction;
        this.userId = userId;
    }
};

export default AccessDeniedException;
