import { IApiAction } from "@/types/api";

class UnauthorizedException extends Error {
    apiAction: IApiAction;

    constructor(apiAction: IApiAction) {
        super(`User is not authorized.`);
        this.apiAction = apiAction;
    }
};

export default UnauthorizedException;
