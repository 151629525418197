/* eslint-disable react-hooks/exhaustive-deps */
'use client';

import { IBottomButtonParams, IPopupParams } from "../types/telegram";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { AppContext } from "../contexts/app-context";
import { useUi } from "../contexts/ui-context";
import { usePathname, useRouter } from "next/navigation";
import { IApiAction, IRedirectAction, IToastAction } from "@/types/api";
import { useTelegram } from "../contexts/telegram-context";
import ApiException from "../exceptions/ApiException";
import UnauthorizedException from "../exceptions/UnauthorizedException";
import AccessDeniedException from "../exceptions/AccessDeniedException";
import { createLogService } from "../services/LoggerService";
import i18n from "../lib/i18n";
import { Locale } from "../types/enums";
import ActionsModal from "@/components/ActionsModal";
import { useStyle } from "@/contexts/style-context";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { setIsConfirmed } from "@/store/appSlice";
import { useDispatch } from "react-redux";

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation();
    const router = useRouter();
    const pathname = usePathname();
    const dispatch = useDispatch();
    const { webApp, initialLocale } = useTelegram();
    const { mainButtonThemes } = useStyle();
    // const { showProgress, hideProgress } = useMainButton(webApp);
    const { isMainMenuOpen, showMainButton, setMainButtonParams, openMainMenu, closeMainMenu, setColorScheme, setThemeParams, showToast, openSettingsMenu, closeSettingsMenu, hideMainButton, hideSecondaryButton, hideBackButton, hideSettingsButton, isSettingsMenuOpen, showBackButton } = useUi();
    const userId = webApp.initDataUnsafe.user?.id;
    const logger = createLogService(webApp.initDataUnsafe.user?.id, 'AppProvider');
    // const [clipboardText, setClipboardText] = useState<string | null>(null);
    const [step, setStep] = useState<number | null>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [storedMainButtonParams, storeMainButtonParams] = useState<IBottomButtonParams | null>(null);
    const homePath = `/${userId}`;
    // const isHomePath = pathname === homePath;

    const toRoute = useCallback((path: string | null) => {
        if (userId) {
            router.push(`${userId}/${path}`);
        } else {
            webApp.close();
        }
    }, [userId, webApp]);

    const enableClosingConfirmation = useCallback(() => {
        if (!webApp.isClosingConfirmationEnabled) webApp.enableClosingConfirmation();
    }, [webApp]);

    const disableClosingConfirmation = useCallback(() => {
        if (webApp.isClosingConfirmationEnabled) webApp.disableClosingConfirmation();
    }, [webApp]);

    const handleMainButtonClicked = useCallback(() => {
        if (isMainMenuOpen) {
            closeMainMenu();
        } else {
            if (webApp.MainButton.text === t('confirm')) {
                dispatch(setIsConfirmed(true));
            } else {
                openMainMenu();
            }
        }
    }, [webApp.MainButton.text, t, isMainMenuOpen, openMainMenu, closeMainMenu]);

    const handleSecondaryButtonClicked = useCallback(() => {
        router.push('/');
    }, [router]);

    const handleBackButtonClicked = useCallback(() => {
        if (step === 1 || !step) {
            router.back();
        } else {
            setStep(step - 1);
        }
    }, [step]);

    const handleSettingsButtonClicked = useCallback(() => {
        if (isSettingsMenuOpen) {
            closeSettingsMenu();
        } else {
            openSettingsMenu();
        }
    }, [isSettingsMenuOpen, closeSettingsMenu, openSettingsMenu]);

    const handleQrTextReceived = useCallback(() => {

    }, []);

    const handleScanQrPopupClosed = useCallback(() => {

    }, []);

    const handlePopupClosed = useCallback(() => {

    }, []);

    const handleClipboardTextReceived = useCallback(() => {
        return new Promise<string>((resolve) => {
            webApp.readTextFromClipboard?.(resolve);
        });
    }, [webApp]);

    const switchInlineQuery = useCallback((query: string, chatType?: ('users' | 'bots' | 'groups' | 'channels')[]) => {
        webApp.switchInlineQuery?.(query, chatType);
    }, [webApp]);

    const handleApiAction = useCallback(async (apiAction: IApiAction) => {
        switch (apiAction.type) {
            case 'close':
                webApp.close();
                break;
            case 'toast':
                showToast(apiAction.action as IToastAction);
                break;
            case 'modal':
                break;
            case 'popup':
                webApp.showPopup(apiAction.action as IPopupParams);
                break;
            case 'redirect':
                const redirectAction = apiAction.action as IRedirectAction;
                toRoute(redirectAction.url);
                break;
        }
    }, [webApp, showToast, toRoute]);

    const handleApiException = useCallback((apiException: ApiException): void => {
        logger.warning(apiException.message);
        if (apiException.apiAction) handleApiAction(apiException.apiAction);
    }, [handleApiAction, logger]);

    const handleUnauthorizedException = useCallback((apiException: UnauthorizedException): void => {
        logger.alert(apiException.message);
        webApp.close();
    }, [webApp]);

    const handleAccessDeniedException = useCallback((apiException: AccessDeniedException): void => {
        logger.alert(apiException.message);
        webApp.close();
    }, [webApp]);

    const handleGeneralError = useCallback((error: Error) => {
        logger.error(`An error occurred. ${error.message}.`, error);
        toRoute('/error');
    }, [toRoute]);

    const handleApiError = useCallback(async (error: any) => {
        if (error instanceof ApiException) {
            handleApiException(error);
        } else if (error instanceof UnauthorizedException) {
            handleUnauthorizedException(error);
        } else if (error instanceof AccessDeniedException) {
            handleAccessDeniedException(error);
        } else {
            handleGeneralError(error);
        }
    }, [handleApiException, handleUnauthorizedException, handleAccessDeniedException, handleGeneralError]);

    const handleThemeChanged = useCallback(() => {
        setColorScheme(webApp.colorScheme);
        setThemeParams(webApp.themeParams);
    }, [setColorScheme, webApp.colorScheme, webApp.themeParams, setThemeParams]);

    const handleLanguageChanged = useCallback(() => {
        logger.info(`Language changed!`);
    }, []);

    const handleLanguageLoaded = useCallback(() => {
        logger.info(`Language loaded!`);
    }, []);

    const handleTelegramThemeChanged = useCallback(() => {
        setColorScheme(webApp.colorScheme);
        setThemeParams(webApp.themeParams);
    }, [setColorScheme, setThemeParams, webApp.colorScheme, webApp.themeParams]);

    const handleLanguageLoadingFailed = useCallback((lng: string) => {
        logger.error(`TelegramProvider: language ${lng} failed loading.`);
        if (lng !== Locale.En) i18n.changeLanguage(Locale.En);
    }, [i18n]);

    useEffect(() => {
        if (i18n) i18n.changeLanguage(initialLocale)
            .catch((err: object | null | undefined) => logger.error('failed to change language.', err));
    }, [initialLocale]);

    const handleViewPortExpanded = useCallback((payload: { isStateStable: boolean }) => {
        if (payload.isStateStable) setIsExpanded(webApp.isExpanded);
    }, [webApp.isExpanded]);

    const handleBeforeUnload = useCallback(() => {
        hideMainButton();
        hideSecondaryButton();
        hideBackButton();
        hideSettingsButton();
    }, [hideMainButton, hideSecondaryButton, hideBackButton, hideSettingsButton]);

    const showMainButtonProgress = useCallback(() => {
        if (!webApp.MainButton.isProgressVisible) webApp.MainButton.showProgress(false);
    }, [webApp.MainButton.isProgressVisible]);

    useEffect(() => {
        if (pathname !== homePath) {
            showBackButton();
            if (step && step > 1) enableClosingConfirmation();
        } else {
            setMainButtonParams(mainButtonThemes.menu);
            hideBackButton();
            disableClosingConfirmation();
        }
    }, [pathname, homePath, step, showBackButton, hideBackButton, enableClosingConfirmation, disableClosingConfirmation]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        webApp.onEvent("themeChanged", handleThemeChanged);
        webApp.onEvent("mainButtonClicked", handleMainButtonClicked);
        webApp.onEvent('secondaryButtonClicked', handleSecondaryButtonClicked);
        webApp.onEvent('backButtonClicked', handleBackButtonClicked);
        webApp.onEvent('settingsButtonClicked', handleSettingsButtonClicked);
        webApp.onEvent('qrTextReceived', handleQrTextReceived);
        webApp.onEvent('scanQrPopupClosed', handleScanQrPopupClosed);
        webApp.onEvent('popupClosed', handlePopupClosed);
        webApp.onEvent('clipboardTextReceived', handleClipboardTextReceived);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            webApp.offEvent("themeChanged", handleThemeChanged);
            webApp.offEvent("mainButtonClicked", handleMainButtonClicked);
            webApp.offEvent("secondaryButtonClicked", handleSecondaryButtonClicked);
            webApp.offEvent("backButtonClicked", handleBackButtonClicked);
            webApp.offEvent("settingsButtonClicked", handleSettingsButtonClicked);
            webApp.offEvent("qrTextReceived", handleQrTextReceived);
            webApp.offEvent("scanQrPopupClosed", handleScanQrPopupClosed);
            webApp.offEvent("popupClosed", handlePopupClosed);
            webApp.offEvent("clipboardTextReceived", handleClipboardTextReceived);
        };
    }, [webApp, handleBeforeUnload, handleThemeChanged, handleMainButtonClicked, handleSecondaryButtonClicked, handleBackButtonClicked, handleSettingsButtonClicked, handleLanguageLoaded, handleLanguageChanged, handleLanguageLoadingFailed, handleTelegramThemeChanged, handleQrTextReceived, handleScanQrPopupClosed, handlePopupClosed, handleClipboardTextReceived, handleViewPortExpanded]);

    return (
        <AppContext.Provider value={{
            step,
            logger,
            setStep,
            toRoute, 
            switchInlineQuery,
            handleApiException,
            showMainButtonProgress,
            handleApiError,
            handleApiAction,
            handleGeneralError,
            handleAccessDeniedException,
            handleUnauthorizedException,
        }}>
            {children}
        </AppContext.Provider>
    )
};
