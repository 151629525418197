import { HapticFeedbackImpactLevel, HapticNotificationType, IHapticFeedback } from '@/types/telegram';
import { useCallback } from 'react';

export type ImpactOccurredFunction = (style: HapticFeedbackImpactLevel) => void;
export type NotificationOccurredFunction = (type: HapticNotificationType) => void;
export type SelectionChangedFunction = () => void;

const useHapticFeedback = (hapticFeedback: IHapticFeedback): readonly [ImpactOccurredFunction, NotificationOccurredFunction, SelectionChangedFunction] => {
    const impactOccurred: ImpactOccurredFunction = useCallback(
        (...args) => hapticFeedback?.impactOccurred(...args),
        [hapticFeedback],
    );
    const notificationOccurred: NotificationOccurredFunction = useCallback(
        (...args) => hapticFeedback?.notificationOccurred(...args),
        [hapticFeedback],
    );
    const selectionChanged: SelectionChangedFunction = useCallback(
        (...args) => hapticFeedback?.selectionChanged(...args),
        [hapticFeedback],
    );

    return [impactOccurred, notificationOccurred, selectionChanged] as const;
};

export default useHapticFeedback;
